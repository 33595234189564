import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  FormControl,
  CardHeader,
  Input,
  InputLabel,
  IconButton,
  useTheme,
  FormControlLabel,
} from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CircleIcon from "@mui/icons-material/Circle";
import ActionAlert from "../../layout/ActionAlert";
import CustomizedSwitch from "../../layout/CustomizedSwitch";

const GeneralInfos = ({ device_id, data }) => {
  const theme = useTheme();
  const [heatingMode, setHeatingMode] = useState([]);
  const [open, setOpen] = useState(false);

  const chauffageMode = ["ELECTRIC", "GAS", "COLLECTIVE", "FUEL", "OTHER"];
  const [checkedChauff, setCheckedChauff] = useState(false);

  const handleChange = (event) => {
    setCheckedChauff(event.target.checked);
  };

  const getValueOrDefault = (value) => {
    return value !== undefined && value !== null ? value : "";
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(device_id)
      .then(() => {
        setOpen(true);
        console.log(`Copied to clipboard: ${device_id}`);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return `${String(date.getDate()).padStart(2, "0")}/${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${date.getFullYear()} ${String(
      date.getHours()
    ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
  };

  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: "#f6c64f",
          color: theme.palette.primary.main,
          padding: "8px 16px",
          display: "flex",
          alignItems: "center",
        }}
        title="Informations générales - Symphonics"
        titleTypographyProps={{ variant: "h6", fontSize: "0.8rem" }}
        action={
          <>
            <IconButton
              aria-label="email"
              sx={{
                padding: "0",
                alignSelf: "center",
              }}
              onClick={handleCopyToClipboard}
            >
              <AlternateEmailIcon sx={{ fontSize: "1rem" }} />
            </IconButton>
            <ActionAlert
              open={open}
              setOpen={setOpen}
              message={"ID copied to clipboard!"}
            />
          </>
        }
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="subtitle1"
          component="div"
          color={theme.palette.primary.main}
          sx={{ fontWeight: "bold" }}
        >
          Données d'activités
        </Typography>

        <Box display="flex" justifyContent="space-between">
          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="statut" shrink>
              Statut
            </InputLabel>
            <Box id="id" display="flex" alignItems="center" sx={{ mt: 3 }}>
              <CircleIcon
                sx={{
                  color: "green",
                  fontSize: "16px",
                }}
              />
              <Typography variant="body2" sx={{ marginLeft: "8px" }}>
                ON
              </Typography>
            </Box>
          </FormControl>

          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="last-contract-update" shrink>
              Plan de chauffe
            </InputLabel>
            <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
              <FormControlLabel
                control={<CustomizedSwitch sx={{ m: 1 }} defaultChecked />}
                label=""
              />
            </Box>
          </FormControl>

          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="last-contract-update" shrink>
              Plan d'effacement
            </InputLabel>
            <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
              <FormControlLabel
                control={<CustomizedSwitch sx={{ m: 1 }} defaultChecked />}
                label=""
              />
            </Box>
          </FormControl>
        </Box>

        <Box sx={{ mt: 5 }} />

        <Box display="flex" justifyContent="space-between">
          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="score-connexion" shrink>
              Score de fiabilité de connexion (%)
            </InputLabel>
            <Input
              type="number"
              id="score-connexion"
              value={formatDate(data?.last_update_contract)}
              readOnly
            />
          </FormControl>

          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="score-plans" shrink>
              Score de fiabilité lors des plans (%)
            </InputLabel>
            <Input
              type="number"
              id="score-plans"
              value={formatDate(data?.last_update_hphc)}
              readOnly
            />
          </FormControl>
        </Box>

        <Box sx={{ mt: 5 }} />

        <Typography
          gutterBottom
          variant="subtitle1"
          component="div"
          color={theme.palette.primary.main}
          sx={{ fontWeight: "bold", mb: 3 }} // Adding margin-bottom for spacing after the title
        >
          Caractéristiques
        </Typography>

        <Box display="flex" justifyContent="space-between" mb={3}>
          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="creation-date" shrink>
              Date de création
            </InputLabel>
            <Input
              id="creation-date"
              value={formatDate(data?.last_update_contract)}
              readOnly
            />
          </FormControl>
          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="update-date" shrink>
              Dernière update
            </InputLabel>
            <Input
              id="update-date"
              value={formatDate(data?.last_update_hphc)}
              readOnly
            />
          </FormControl>
        </Box>

        <Box display="flex" justifyContent="space-between" mb={3}>
          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="device-type" shrink>
              Type d'appareil
            </InputLabel>
            <Input
              id="device-type"
              value={formatDate(data?.last_update_contract)}
              readOnly
            />
          </FormControl>

          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="localisation" shrink>
              Localisation
            </InputLabel>
            <Input
              id="localisation"
              value={formatDate(data?.last_update_hphc)}
              readOnly
            />
          </FormControl>
        </Box>

        <FormControl variant="standard" fullWidth sx={{ mb: 3 }}>
          <InputLabel htmlFor="model" shrink>
            Modèle
          </InputLabel>
          <Input id="model" value={data["address"]} readOnly />
        </FormControl>

        <FormControl variant="standard" fullWidth sx={{ mb: 3 }}>
          <InputLabel htmlFor="master" shrink>
            Master
          </InputLabel>
          <Input id="master" value={data["address"]} readOnly />
        </FormControl>

        <Box display="flex" justifyContent="space-between" mb={3}>
          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="n-serie" shrink>
              N° série
            </InputLabel>
            <Input
              id="n-serie"
              value={formatDate(data?.last_update_contract)}
              readOnly
            />
          </FormControl>

          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="provider" shrink>
              Nom du device (provider)
            </InputLabel>
            <Input
              id="provider"
              value={formatDate(data?.last_update_hphc)}
              readOnly
            />
          </FormControl>
        </Box>

        <FormControl variant="standard" fullWidth sx={{ mb: 3 }}>
          <InputLabel htmlFor="parent-device" shrink>
            Parent device
          </InputLabel>
          <Input id="parent-device" value={data["address"]} readOnly />
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default GeneralInfos;
